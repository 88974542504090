import React from 'react';
import { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import IconWrap from '@bridebook/ui/src/components/svg/icon-wrap';
import { IStylingProps } from '@bridebook/ui/src/themes/types';
import { TColor } from '@bridebook/ui/src/types';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconMail = ({ id, color, width = 16, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 16;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none">
      <rect
        x="2.6665"
        y="4"
        width="10.6667"
        height="8"
        rx="0.666667"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 6L7.70169 8.5176C7.88938 8.61144 8.11029 8.61144 8.29798 8.5176L13.3332 6"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconMail;
