import React, { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { IconChevronRight, IconCross } from '@bridebook/ui/src/icons';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import BorderWrap from '../border-wrap/border-wrap';
import componentStyles, { getThemeColor } from './well.style';

export interface ISharedTypes {
  size?: string;
  iconSize?: number;
  theme?: string;
  noPadding?: boolean;
  withChevron?: boolean;
}

export interface IProps extends ISharedTypes, IStylingProps, PropsWithChildren {
  heading?: string;
  icon?: ReactNode;
  borderless?: boolean;
  slim?: boolean;
  withChevron?: boolean;
  style?: FelaCSS;
  onClose?: MouseEventHandler<ReactNode>;
}

/**
 * @deprecated
 * Use SupportMessage instead
 */
export const Well = ({
  borderless,
  size,
  theme,
  children,
  heading,
  icon,
  iconSize = 20,
  slim,
  noPadding,
  onClose,
  withChevron,
  style = {},
  ...restProps
}: IProps) => {
  const themeColor = getThemeColor(theme);
  const styles = componentStyles({ size, iconSize, theme, noPadding, withChevron });

  return (
    <Box style={mergeStyles([styles.wrapper, style])} {...restProps}>
      <BorderWrap color={themeColor.primary} slim={slim} borderless={borderless}>
        <Box style={styles.container}>
          {icon && <Box style={styles.iconWrapStyle}>{icon}</Box>}
          <Box style={styles.contentWrap}>
            {heading && (
              <Box as="h3" style={styles.headingStyle}>
                {heading}
              </Box>
            )}
            {children && <Box style={styles.childrenWrap}>{children}</Box>}
          </Box>
          {!!onClose && (
            <Box style={styles.onCloseButton} onClick={onClose}>
              <IconCross width={12} />
            </Box>
          )}
          {!!withChevron && !onClose && (
            <Box style={styles.chevronWrap}>
              <IconChevronRight color="space" />
            </Box>
          )}
        </Box>
      </BorderWrap>
    </Box>
  );
};

export default Well;
